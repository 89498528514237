import React from 'react';
import { Tooltip } from 'react-tooltip';

export default function InputLabel({ value, className = '', tooltip, forInput, children, ...props }) {
    // Generate a unique ID for associating the label with its tooltip
    const tooltipId = tooltip ? `tooltip-${Math.random().toString(36).substr(2, 9)}` : '';

    // Function to highlight '*' in red
    const highlightAsterisk = (text) => {
        const parts = text.split('*');
        return parts.map((part, index) => (
            <React.Fragment key={index}>
                {part}
                {index < parts.length - 1 && <span className="text-red-500">*</span>}
            </React.Fragment>
        ));
    };

    return (
        <div className={`flex items-center gap-2 text-sm font-medium ${className}`}>
            <label {...props} className="block text-gray-700" data-tip={tooltip} data-for={tooltipId}>
                {value ? highlightAsterisk(value) : children}
            </label>
            {tooltip && (
                <div>
                    <a className="text-[#5ba4bd] relative -top-2" data-tooltip-id={tooltipId} data-tooltip-content={tooltip}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                        </svg>
                    </a>
                    <Tooltip id={tooltipId} />
                </div>
            )}
        </div>
    );
}
